import "./style.scss"
import React from "react"

const imageData1x = [
  "https://a.storyblok.com/f/177206/480x480/fb0e1c7c1a/ft_dp_1.png",
  "https://a.storyblok.com/f/177206/480x480/4adadad094/ft_dp_2.png",
  "https://a.storyblok.com/f/177206/480x480/32cb4aa156/ft_dp_3.png",
  "https://a.storyblok.com/f/177206/520x480/82c3aea34b/ft_dp_4.png",
  "https://a.storyblok.com/f/177206/480x480/820fc38b7c/ft_dp_5.png",
]
const imageData2x = [
  "https://a.storyblok.com/f/177206/960x960/318b8972e6/ft_dp_1_2x.webp",
  "https://a.storyblok.com/f/177206/960x960/b8458150a5/ft_dp_2_2x.webp",
  "https://a.storyblok.com/f/177206/960x960/ed02275a1f/ft_dp_3_2x.webp",
  "https://a.storyblok.com/f/177206/1040x960/def2b867be/ft_dp_4_2x.webp",
  "https://a.storyblok.com/f/177206/960x960/f849b324ce/ft_dp_5_v3_2x.webp",
]

const FeatureDisplay = () => {
  const ignoreSrc=typeof window === "undefined"
  const FeatureData = [
    {
      feature:
        "Build your online presence and feel together with our virtual office",
      moreTips: [],
      des: [
        "Easy to connect with your clients from anywhere. You'll feel like you're all in the same place, even if you're not.",
      ],
      img: imageData2x[0],
      needFixMarginTop: false,
    },
    {
      feature:
        "Get your prospects convinced by your reputation before they reach out.",
      moreTips: [
        "Your link-in-bio and intro video can showcase your business and yourself.",
        "And the long string of visitors will demonstrate how popular you are.",
      ],
      des: [],
      img: imageData2x[1],
      needFixMarginTop: false,
    },
    {
      feature: "One-click for guests to start video chats when they come by",
      des: [
        "Guests would like to tap your avatar to initiate video talks while seeing you there in an in-person way.",
      ],
      img: imageData2x[2],
      needFixMarginTop: false,
      needAnimation: true,
    },
    {
      feature: "Or stay engaged through chat and scheduling options",
      des: [
        "Guests can also send you messages, or schedule a meeting when you are busy or off-line.",
      ],
      img: imageData2x[3],
      needFixMarginTop: false,
    },
    {
      feature: "And use Cubo to simplify your tool stack and save money",
      moreTips: [
        "Link-in-Bio tool to replace Linktree",
        "Scheduler link to replace Calendly",
        "Meeting link to replace Zoom",
        'CuboGPT to replace ChatGPT',
        'Website Integration to replace intercom'
      ],
      des: [
        "All links lead the traffic directly to your online office for long-term engagement.",
      ],
      img: imageData2x[4],
      needFixMarginTop: true,
    },
  ]
  return (
    <section className="feature-display-section">
      <div className="wrapper">
        <h2 className="title-top">
          A One-Stop Solution to Empower your Communication with Audiences in a
          More Human Way
        </h2>
        <div className="content">
          {FeatureData.map((content, index) => (
            <div className="item" key={index}>
              <div className="left">
                <h3 className="top">{content.feature}</h3>
                {content.moreTips && content.moreTips.length > 0 && (
                  <ul className="moreTips">
                    {content.moreTips.map((item, index) => {
                      return (
                        <li className="des" key={index}>
                           {item}
                        </li>
                      )
                    })}
                  </ul>
                )}
                {content.des &&
                  content.des.length > 0 &&
                  content.des.map((item, index) => {
                    return (
                      <p
                        className={`des ${
                          content.needFixMarginTop ? "mt-32" : ""
                        }`}
                        key={index}
                      >
                        {item}
                      </p>
                    )
                  })}

                <a href="/register">
                  <div className="btn">Try for Free &rarr;</div>
                </a>
              </div>
              <div className="right">
                <img
                  src={content.img}
                  width={480}
                  height={480}
                  loading="lazy"
                />

                {content.needAnimation && (
                  <div
                    id="index-camera-animate-video"
                    style={{ top: "84px", right: "224px" }}
                  >
                    <img
                      src={ignoreSrc? '':"assets/svgs/index-video-animation.svg"}
                      alt="camera"
                    />
                    <div className="banner-office-big animated-amplification moveBiggest " />
                    <div className="banner-office-inner animated-amplification moveBiggest delay" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default FeatureDisplay
