import "./style.scss"
import React from "react"

const imageData1x = [
  "https://a.storyblok.com/f/177206/512x336/0571ed9858/step_1_4.png",
  "https://a.storyblok.com/f/177206/480x320/6edc22bd84/step_2_4.png",
  "https://a.storyblok.com/f/177206/480x320/514fca1a3d/step_3_4.png",
]
const imageData2x = [
  "https://a.storyblok.com/f/177206/1024x672/3624de1451/step_1_4_2x.webp",
  "https://a.storyblok.com/f/177206/960x640/689e07578f/step_2_4_2x.webp",
  "https://a.storyblok.com/f/177206/960x640/09f55621c7/step_3_4_2x.webp",
]

const StepData = [
  {
    step: "Step 1 - Set up your office in 2 minutes",
    des: [
      { icon: "assets/svgs/step_1_1.svg", text: "Customize your profile" },
      {
        icon: "assets/svgs/step_1_2.svg",
        text: "Manager your scheduling time",
      },
      { icon: "assets/svgs/step_link.svg", text: "Edit your Link-in-bio" },
    ],
    img: imageData2x[0],
  },
  {
    step: "Step 2 - Share your office link everywhere",
    des: [
      {
        icon: "assets/svgs/step_2_1.svg",
        text: "Integrate into your websites",
      },
      { icon: "assets/svgs/step_2_2.svg", text: "Insert in email signatures" },
      { icon: "assets/svgs/step_link.svg", text: "Add to social media bios" },
    ],
    img: imageData2x[1],
  },
  {
    step: "Step 3 - Stay online in your office and respond immediately",
    des: [
      { icon: "assets/svgs/step_3_1.svg", text: "Casual instant video call" },
      { icon: "assets/svgs/step_3_2.svg", text: "Chat messaging" },
      { icon: "assets/svgs/step_3_3.svg", text: "Schedule appointments" },
    ],
    img: imageData2x[2],
  },
]

const ThreeStepBlok = () => {
  const ignoreSrc = typeof window === "undefined"

  return (
    <section className="three-step-section">
      <h2 className="top">
        The New Way of Communication to Boom Your Clients and Contracts in 3
        Easy Steps!
      </h2>
      <div className="content">
        {StepData.map((content, index) => (
          <div className="item" key={`Step-${index}`}>
            <div className="left">
              <div className="circle" />
              <div className="step">
                <h2 className="top">{content.step}</h2>
                {content.des.map((item, index) => {
                  return (
                    <h3 className="tip" key={index}>
                      <img
                        src={item.icon}
                        width={24}
                        height={24}
                        loading="lazy"
                      />{" "}
                      {item.text}
                    </h3>
                  )
                })}
              </div>
            </div>
            <div className="right">
              {index === 2 && (
                <div
                  id="index-camera-animate-video"
                  style={{ right: "208px", top: "56px" }}
                >
                  <div className="banner-office-big animated-amplification moveBiggest" />
                  <div className="banner-office-inner animated-amplification moveBiggest delay" />
                  <img
                    src={
                      ignoreSrc ? "" : "assets/svgs/index-video-animation.svg"
                    }
                    alt="camera"
                  />
                </div>
              )}
              <img src={content.img} width={480} height={320} loading="lazy" />
            </div>
          </div>
        ))}
        <div className="left-line" />
      </div>
      <a href="/register">
        <div className="btn">Try for Free &rarr;</div>
      </a>
    </section>
  )
}
export default ThreeStepBlok
