import "../style.scss"
import React from "react"
import { imgList } from './index'

const ContentData = {
  des: 'Forget Zoom, Calendly, Slack, and Linktree. Get all the features in one platform for all types of business connections.',
}

const Page3 = () => {
  return (
    <div className="page page3">
      <div className="page3-title">
         <span className="style-text">1</span> Online Office. <br />
         <span className="style-text">6</span> Powerful Tools. <br />
        Win Contracts and <span className="style-text">Save Big</span>.
      </div>

      {/* <div className="page3-des">{ ContentData.des }</div> */}

      <img className="page3-img" src={imgList.oneContainSix} />
    </div>
  )
}
export default Page3
